import { mapActions, mapGetters, mapMutations } from 'vuex'

import answerTemplate from '../../components/answer-template/index.vue'
import answerViewHeader from '../../components/dialog-view-header/index.vue'

export default {
  name: 'test-results-dialog-view',
  data () {
    return {
      dialog: true,
      payload: {
        userComment: ''
      }
    }
  },
  components: {
    answerTemplate,
    answerViewHeader
  },
  computed: {
    ...mapGetters({
      showDialogView: 'testsResults/showDialogView',
      isLoading: 'testsResults/isLoading',
      currentItem: 'testsResults/currentItem',
      editId: 'testsResults/editId',
      list: 'testsResults/list'
    })
  },
  created () {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchData: 'testsResults/GET_LIST_ITEM',
      complete: 'testsResults/COMPLETE_TEST'
    }),
    ...mapMutations({
      setItem: 'testsResults/SET_ITEM',
      changeShowDialog: 'testsResults/CHANGE_SHOW_DIALOG',
      changeEditId: 'testsResults/CHANGE_EDIT_ID'
    }),
    createRequestPayload () {
      const formData = new FormData()
      formData.append('user_comment', this.payload.userComment)
      formData.append('_method', 'patch')
      formData.append('userTestId', this.currentItem.id)
      formData.append('testId', this.$route.params.testId)
      return formData
    },
    completeTest () {
      this.complete(this.createRequestPayload())
    }
  },
  destroyed () {
    this.setItem(null)
  }
}
