export default {
  name: 'dialog-view-header',
  props: {
    item: {},
    testName: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    totalPoints: {},
    userPoints: {}
  }
}
