import { mapActions, mapGetters, mapMutations } from 'vuex'

import mixins from '@/mixins'

import filterResultUsers from '../../components/filter-result-users/index.vue'
import viewDialog from '../dialog-view/index.vue'

export default {
  name: 'test-results-users',
  mixin: [mixins],
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removedItem: null,
      editedItem: null
    }
  },
  components: {
    filterResultUsers,
    viewDialog
  },
  computed: {
    ...mapGetters({
      list: 'testsResults/list',
      limit: 'testsResults/limit',
      skip: 'testsResults/skip',
      isListLoading: 'testsResults/isListLoading',
      isLoading: 'testsResults/isLoading',
      listLength: 'testsResults/listLength',

      showDialogView: 'testsResults/showDialogView',
      isResultLoading: 'testsResults/isResultLoading'
    }),
    headers () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'User email',
          value: 'user',
        },
        {
          text: 'Started at',
          value: 'startedAt'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.fetchList(this.$route.params)

    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'testsResults/GET_LIST',
      cancel: 'testsResults/CANCEL'
    }),
    ...mapMutations({
      setItem: 'testsResults/SET_ITEM',
      setList: 'testsResults/SET_LIST',
      changeSkip: 'testsResults/CHANGE_SKIP',
      changeShowDialog: 'testsResults/CHANGE_SHOW_DIALOG',
      changeEditId: 'testsResults/CHANGE_EDIT_ID'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialogView (item, status) {
      this.changeShowDialog(status)
      this.changeEditId(item.id)
    },
    cancelListItem () {
      const formData = new FormData()
      formData.append('userTestId', this.removedItem.id)
      formData.append('testId', this.$route.params.testId)
      formData.append('_method', 'patch')
      this.cancel(formData).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_cancel'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removedItem = item
    }
  },
  beforeDestroy () {
    this.setList([])
  }
}
