import answerOptions from '../answer-option/index.vue'
import answerOptionsAndAnother from '../answer-options-and-another/index.vue'
import answerText from '../answer-text/index.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'test-result-template',
  props: {
    item: {
      type: Object
    },
    testId: {
      type: Number
    },
    index: {
      type: Number
    }
  },
  data () {
    return {
      isLoading: false,
      isTrueAnotherAnswer: false
    }
  },
  components: {
    answerOptions,
    answerText,
    answerOptionsAndAnother
  },
  computed: {
    ...mapGetters({
      currentItem: 'testsResults/currentItem'
    }),
    userAnswers () {
      const filteredList = this.item.answerVariants.data.filter(e => {
        return e.userAnswer.data.hasOwnProperty('id')
      })

      return filteredList.map(e => {
        return e.userAnswer.data
      })
    }
  },
  methods: {
    ...mapActions({
      accept: 'testsResults/ACCEPT_QUESTION',
      decline: 'testsResults/DECLINE_QUESTION'
    }),
    createRequestPayload (isCorrectTextAnswer) {
      const formData = new FormData()
      formData.append('testId', this.testId)
      formData.append('id', this.item.userQuestion.data.id)
      formData.append('_method', 'patch')
      if (this.item.type === 'options_and_own_answer') {
        this.userAnswers.forEach((e, index) => {
          if (e.text) {
            formData.append(`answers[${index}][id]`, e.id)
            formData.append(`answers[${index}][correct]`, this.isTrueAnotherAnswer ? 1 : 0)
          } else {
            formData.append(`answers[${index}][id]`, e.id)
            formData.append(`answers[${index}][correct]`, e.correct ? 1 : 0)
          }
        })
      } else if (this.item.type === 'text') {
        this.userAnswers.forEach((e, index) => {
          formData.append(`answers[${index}][id]`, e.id)
          formData.append(`answers[${index}][correct]`, isCorrectTextAnswer)
        })
      } else {
        this.userAnswers.forEach((e, index) => {
          formData.append(`answers[${index}][id]`, e.id)
          formData.append(`answers[${index}][correct]`, e.correct ? 1 : 0)
        })
      }
      return formData
    },
    changeResultQuestion (type) {
      this.isLoading = true
      let isCorrectTextAnswer = 0
      if (type === 'accept') {
        if (this.item.type === 'text') {
          isCorrectTextAnswer = 1
        }
        this.accept(this.createRequestPayload(isCorrectTextAnswer)).then(() => {

        }).finally(() => {
          this.isLoading = false
        })
      } else {
        if (this.item.type === 'text') {
          isCorrectTextAnswer = 1
        }
        this.decline(this.createRequestPayload(isCorrectTextAnswer)).then(() => {

        }).finally(() => {
          this.isLoading = false
        })
      }
    }
  }
}
