export default {
  name: 'test-answer-options-and-another',
  props: {
    item: {}
  },
  data () {
    return {
      isTrueAnswer: false
    }
  },
  methods: {
    updateCheckbox (val) {
      this.$emit('input', this.isTrueAnswer)
    }
  }
}
