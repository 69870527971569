import { mapActions, mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'filter-tests-result-users',
  data () {
    return {
      search: '',
      searchInput: '',
      filter: {
        user: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      setting: 'education/settings',
      isSettingLoading: 'education/isSettingsLoading',

      basicUsers: 'user/basicUsers',
      basicUsersLoading: 'user/basicUsersLoading'
    })
  },
  created () {
    this.fetchEducationSetting()
  },
  methods: {
    ...mapActions({
      fetchUsersBasic: 'user/GET_BASIC_USERS',
      fetchEducationSetting: 'education/GET_SETTINGS',
      fetchList: 'testsResults/GET_LIST'
    }),
    ...mapMutations({
      changeFilter: 'testsResults/CHANGE_FILTER',
      resetFilter: 'testsResults/RESET_FILTER',

    }),
    // users
    changeInputUsers: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchUsersBasic({
          keywords: val
        })
      }
    }, 500),
    changeFilterLocal (val, type) {
      this.changeFilter({
        value: val,
        type: type
      })
      this.fetchList(this.$route.params)
    },
    autoCompleteChanged (e, type) {
      if (!e) {
        this.filter.user = ''
        this.search = ''
      } else {
        this.filter.user = e.id
        this.search = e
      }

      this.changeFilter({
        value: e.hasOwnProperty('id') ? e.id : '',
        type: type
      })
      this.fetchList(this.$route.params)
    }
  },
  destroyed () {
    this.resetFilter()
  }
}
